import React, { useState, useEffect } from 'react';
import { useDropzone, FileWithPath } from 'react-dropzone';
import { useAuth0 } from '@auth0/auth0-react';
import { consolidateDynamic, downloadS3, uploadS3 } from '../utils/api';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import { DocumentIcon } from '@heroicons/react/20/solid'


const DomoMain: React.FC = () => {
  const [auth0Token, setAuth0Token] = useState('');
  const { getAccessTokenSilently } = useAuth0();


  // run only on once
  useEffect(() => {
    const fetchAccessToken = async () => {
      const token = await getAccessTokenSilently();
      setAuth0Token(token);
    };
    fetchAccessToken().catch(console.error);
  }, [getAccessTokenSilently]);

  return (
    <div>
      <div className="border-dashed border-2 p-20 bg-white text-center
        cursor-pointer block mb-5 ml-5 mr-10 rounded-lg shadow-lg" >
          <div className="flex justify-center items-center">
            CARDS
          </div>
          <iframe src="https://safeguardglobal-sandbox.domo.com/embed/card/private/VAp05" width="600" height="600" frameBorder="0"></iframe>
          <iframe src="https://safeguardglobal-sandbox.domo.com/embed/card/private/XLxkk" width="600" height="600" frameBorder="0"></iframe>
          <iframe src="https://safeguardglobal-sandbox.domo.com/embed/card/private/YWpl0" width="600" height="600" frameBorder="0"></iframe>
      </div>

      <div className="border-dashed border-2 p-20 bg-white text-center
        cursor-pointer block mb-5 ml-5 mr-10 rounded-lg shadow-lg" >
          <div className="flex justify-center items-center">
            PDP
          </div>
          <iframe src="https://safeguardglobal-sandbox.domo.com/embed/card/private/2x41J" width="600" height="600" frameBorder="0"></iframe>
          <iframe src="https://safeguardglobal-sandbox.domo.com/embed/card/private/6XoKQ" width="600" height="600" frameBorder="0"></iframe>

      </div>
    </div>
  );
};


export default DomoMain;
