import * as ReactDOMClient from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import './index.css';
import { Auth0ProviderWithNavigate } from './components/Auth0ProviderWithNavigate';
import Layout from './components/Layout';
import Domo from './Domo';
import reportWebVitals from './reportWebVitals';

const root = ReactDOMClient.createRoot(
  document.getElementById('root') as HTMLInputElement,
);

const ProtectedDomo = withAuthenticationRequired(Domo);

root.render(
  <BrowserRouter>
    <Auth0ProviderWithNavigate>
      <Routes>
        <Route
          path="/"
          element={
            <Layout activeSubpage="Domo App">
              <ProtectedDomo />
            </Layout>
          }
        />
      </Routes>
    </Auth0ProviderWithNavigate>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
