import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import DomoMain from './components/DomoMain';
import './App.css';
import { checkPQAuth0UserRole } from './utils/auth0';

export function Domo() {
  document.title = 'Domo App';
  const { user } = useAuth0();

  //TODO Disabled until we figure out what users will have this role
  //checkPQAuth0UserRole(user);

  return (
    <>
      <div className="bg-gray-100 shadow rounded-lg">
        <div className="">
          <header>
            <div className="py-6 px-4 sm:px-6">
              <h1 className="text-2xl font-bold tracking-tight text-gray-900">
                Domo App
              </h1>
              <p className="mt-2 max-w-8xl text-sm text-gray-700">
                Welcome to the Domo App.
              </p>
            </div>
          </header>

          <main className="px-4 py-5 sm:p-6">
            <DomoMain />
          </main>

        </div>
      </div>
    </>
  );
}

export default Domo;
